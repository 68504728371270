import styled from "styled-components";

/* ============================================================================ */
/*          STYLED - Styled Components
/* ============================================================================ */

export const ContactHeader = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ContactHeaderImage = styled.img`
  position: relative;
  top: 70px;
  width: 600px;
  border-radius: 31% 69% 39% 61% / 41% 46% 54% 59%;
  padding: 1.5rem 0;
  z-index: -1;

  @media screen and (max-width: 480px) {
    width: 400px;
  }

  @media screen and (max-width: 350px) {
    left: 1.5rem;
  }
`;

export const ContactTitle = styled.h1`
  position: relative;
  top: 40px;
  left: -300px;
  z-index: -1;
  text-align: center;

  @media screen and (max-width: 480px) {
    left: -13rem;
  }
`;

export const ContactDescription = styled.section`
  margin: 4.5rem 0 0 5rem;
  width: 80%;
  line-height: 1.5;

  @media screen and (max-width: 480px) {
    margin-left: 1rem;
  }
`;

export const ContactInfo = styled.div`
  line-height: 1.5;

  h3 {
    margin-left: 5rem;
    padding-bottom: 1rem;
  }

  p {
    margin-left: 5rem;
    padding-bottom: 0.5rem;
  }

  @media screen and (max-width: 480px) {
    margin-left: -4rem;
  }
`;

export const ContactSubTitle = styled.h2`
  margin: 2rem 0 1rem 5rem;
`;

export const ContactButton = styled.button`
  border: none;
  padding: 0.5rem 0.5rem;
  cursor: pointer;
  background-color: transparent;
  border-radius: 5px;
  transition: 0.3s;
  margin-left: 5rem;

  &:hover {
    background-color: rgba(48, 48, 48, 0.1);
  }

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }

  @media screen and (max-width: 480px) {
    margin-left: 1rem;
  }
`;

export const ContactDetails = styled.div`
  margin: 1rem 0 0 5rem;
  padding-bottom: 1rem;

  p {
    padding-bottom: 0.5rem;
  }

  @media screen and (max-width: 480px) {
    margin-left: 1rem;
  }

  h3 {
    padding-bottom: 1rem;
  }
`;
