import React from "react";
import ProductInfo from "./ProductInfo";
import { MdOutlineFileDownload } from "react-icons/md";
import { useTranslation } from 'react-i18next';

/* ---------------------------------------------------------------------------- */

import {
  DownloadButton,
  DownloadList,
  ProductDescription,
  ProductDocument,
  ProductImage,
  ProductSection,
} from "./StyledComponents";

/* ----------------------------------------------------------------------------
 *          IMAGES
 * --------------------------------------------------------------------------- */

import WellBeeWallImage from "../../../../data/images/products/Wellbee Wall/wellbee_wall.png";

/* ----------------------------------------------------------------------------
 *          DOWNLOAD FILES
 * --------------------------------------------------------------------------- */

import WellbeeProduktblad from "../../../../data/downloads/wellbee-produktblad.pdf";
import FI_WellbeeProduktblad from "../../../../data/downloads/FI_Wellbee_Produktblad.pdf"
import NO_WellbeeManual from "../../../../data/downloads/NO_Brukermanual_wellbee.pdf"

/* ============================================================================ */
/*          TYDLIG KALENDER PRO - function
/* ============================================================================ */

const WellbeeWall = () => {  
  const { t } = useTranslation("products/wellbeeWall"); 

  return (
    <main className="product container">
      <ProductSection>
        <h1>{t('h1_wellbeeWall')}</h1>
        <ProductImage src={WellBeeWallImage} alt="" />

        <ProductDescription>
          <p>{t('p1_wellbeeWall')}</p>
          <p>{t('p2_wellbeeWall')}</p>
          <p>{t('p3_wellbeeWall')}</p>
          <p>{t('p4_wellbeeWall')}</p>
          <p>{t('p5_wellbeeWall')}</p>
          <p>{t('p6_wellbeeWall')}</p>
          <h3>{t('h3_wellbeeWall')}</h3>
          <ul>
            <li>{t('artikelnr_wellbeeWall')}: N8010-24</li>
          </ul>
        </ProductDescription>

        <ProductInfo titel={t('dokument_wellbeeWall')}>
          <ProductDocument>
            <ul>
              <p>{t('laddaner_wellbeeWall')}</p>

              <DownloadList>
                <DownloadButton>
                  <a href={WellbeeProduktblad} download="Wellbee_Produktblad.pdf">
                    <span>Wellbee Produktblad</span>
                    <MdOutlineFileDownload />
                  </a>
                </DownloadButton>

                <DownloadButton>
                  <a href={FI_WellbeeProduktblad} download="FI_Wellbee_Produktblad.pdf">
                    <span>Wellbee Produktblad På Finska</span>
                    <MdOutlineFileDownload />
                  </a>
                </DownloadButton>
                
                <DownloadButton>
                  <a href={NO_WellbeeManual} download="NO_Brukermanual_wellbee.pdf">
                    <span>Wellbee Manual På Norska</span>
                    <MdOutlineFileDownload />
                  </a>
                </DownloadButton>
              </DownloadList>
            </ul>
          </ProductDocument>
        </ProductInfo>

      </ProductSection>
    </main>
  );
};

export default WellbeeWall;
