import React, { useState, useEffect } from "react";
import { useUser } from "../../stores/userContext";
import { useTranslation } from "react-i18next";
import { GetTranslatedSidebar } from "./SidebarData";

/* ---------------------------------------------------------------------------- */

import { FaBars } from "react-icons/fa";
import { AiFillCloseCircle } from "react-icons/ai";
import { IconContext } from "react-icons/lib";
import LanguagePicker from "../language/LanguagePicker"

/* ---------------------------------------------------------------------------- */

import SubMenu from "./SubMenu";
import Logo from "../../data/images/logos/brstrom-logo.png";
import {
  Call,
  CallUs,
  FaBarsText,
  LoginButton,
  LoginLink,
  Nav,
  NavClose,
  NavIcon,
  NavLogo,
  SidebarNav,
  SidebarWrap,
  BottomSection,
  TopSection,
  NavRight,
  NavLeft,
} from "./StyledComponents";

/* ============================================================================ */
/*          SIDEBAR - Function
/* ============================================================================ */

/* --- Öppna och stänga sidebar --- */
const Sidebar = () => {
  const [sidebar, setSidebar] = useState(false);
  const showSidebar = () => setSidebar(!sidebar);

  /* --- Navbar som syns när man scrollar ner --- */
  const [show, setShow] = useState(true);
  const controlNavbar = () => {
    if (window.scrollY > 5) {
      setShow(false);
    } else {
      setShow(true);
    }
  };

  /* --- Knapp visas om ibloggad --- */

  const { authenticated } = useUser();
  const { t, i18n } = useTranslation("sidebar"); 

  const translatedSidebar = GetTranslatedSidebar(i18n.language);

  useEffect(() => {
    window.addEventListener("scroll", controlNavbar);
    return () => {
      window.removeEventListener("scroll", controlNavbar);
    };
  }, []);

  return (
    <>
      <IconContext.Provider value={{ color: "var(--icon-color)" }}>
      <div className={`navbar ${show && "navbar_transparent"}`}>
          <Nav>
            <NavLeft>
              <a href="/">
                <NavLogo img src={Logo} alt="Br Ström" />
              </a>
            </NavLeft>
            
            <NavRight>
                <LanguagePicker sidebar={sidebar}/>
 
              <NavIcon to="#" onClick={showSidebar}>
                <FaBars />
                <FaBarsText>{t('menu')}</FaBarsText>
              </NavIcon>
            </NavRight>
          </Nav>
        </div>

        <SidebarNav sidebar={sidebar}>
        <SidebarWrap>
          <NavClose>
            <NavIcon to="#">
              <AiFillCloseCircle onClick={showSidebar} />
            </NavIcon>
          </NavClose>

          {/* Top Section */}
          <TopSection>
            {translatedSidebar.map((item, index) => {
              // Check if the item should only be shown if authenticated
              if (item.showOnlyIfAuthenticated && !authenticated) {
                return null;
              }

              return <SubMenu item={item} key={index} />;
            })}
          </TopSection>
           
          <BottomSection>
            {!authenticated && (
              <LoginButton>
                <LoginLink to="/login">
                  <span>Login</span>
                </LoginLink>
              </LoginButton>
            )} 
            
            <Call>
              <CallUs>
                {t('call_us')}
                <br />
                <b>020-103370</b>
              </CallUs>
            </Call>
          </BottomSection>
        </SidebarWrap>
      </SidebarNav>
      </IconContext.Provider>
    </>
  );
};

export default Sidebar;
