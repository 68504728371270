import React from 'react';
import i18n from 'i18next';
import { useState } from 'react';

import {
  LanguageSelectorWrapper,
  LanguageSelectorButton,
  Dropdown,
  DropdownItem,
  LanguageIcon,
  ArrowDownIcon
  } from "./StyledComponents"


const LanguagePicker = ({ sidebar }) => {
  const [open, setOpen] = useState(false);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setOpen(false);
  };

  const toggleDropdown = () => {
    setOpen(!open);
  }

  return (
      <LanguageSelectorWrapper className={sidebar ? 'navOpen' : 'navClosed'}>
      <LanguageSelectorButton onClick={toggleDropdown}>
        <LanguageIcon />
        <ArrowDownIcon  />
      </LanguageSelectorButton>
      <Dropdown open={open}>
        <DropdownItem onClick={() => changeLanguage("sv")}>
          Svenska
        </DropdownItem>
        <DropdownItem onClick={() => changeLanguage("en")}>
          English
        </DropdownItem>
        <DropdownItem onClick={() => changeLanguage("no")}>
          Norsk
        </DropdownItem>
        <DropdownItem onClick={() => changeLanguage("fi")}>
          Suomeksi
        </DropdownItem>
      </Dropdown>
    </LanguageSelectorWrapper>
  );
};


export default LanguagePicker;
