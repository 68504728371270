import React from "react";
import ProductInfo from "./ProductInfo";
import { MdOutlineFileDownload } from "react-icons/md";
import { useTranslation } from 'react-i18next';

/* ---------------------------------------------------------------------------- */

import {
  DownloadButton,
  DownloadList,
  ProductDescription,
  ProductDocument,
  ProductImage,
  ProductSection,
} from "./StyledComponents";

/* ----------------------------------------------------------------------------
 *          IMAGES
 * --------------------------------------------------------------------------- */

import PortKommHandenhet from "../../../../data/images/products/Port Komm/Handenhet.png";
import PortKommKamrta from "../../../../data/images/products/Port Komm/portkommkamera.png";

/* ----------------------------------------------------------------------------
 *          DOWNLOAD FILES
 * --------------------------------------------------------------------------- */

import PortkommProduktblad from "../../../../data/downloads/port-komm_produktblad.pdf";
import PortkommReservdelslista from "../../../../data/downloads/port_komm_reservdelslista.pdf";


/* ============================================================================ */
/*          PORT | KOMM - function
/* ============================================================================ */


const PortKomm = () => {
  
  const { t } = useTranslation("products/portkomm"); 

  return (
    <main className="product container">
      <ProductSection>
        <h1>{t('h1_portkomm')}</h1>
        <ProductImage src={PortKommHandenhet} alt="" />
        <ProductImage src={PortKommKamrta} alt="" />

        <ProductDescription>
          <p>{t('p1_portkomm')}</p>
          <p>{t('p2_portkomm')}</p>
          <p>{t('p3_portkomm')}</p>
        </ProductDescription>

        <ProductInfo titel={t('dokument_portkomm')}>
          <ProductDocument>
            <ul>
              <p>{t('laddaner_portkomm')}</p>

              <DownloadList>
                <DownloadButton>
                  <a href={PortkommProduktblad} download="port-komm_produktblad.pdf">
                    <span>port | komm Produktblad</span>
                    <MdOutlineFileDownload />
                  </a>
                </DownloadButton>
              </DownloadList>
            </ul>
          </ProductDocument>
        </ProductInfo>

        <ProductInfo titel={t('reservdelar_portkomm')}>
          <ProductDocument>
            <ul>
              <p>{t('olikadelar_portkomm')}</p>

              <DownloadList>
                <DownloadButton>
                  <a href={PortkommReservdelslista} download="port_komm_reservdelslista.pdf">
                    <span>port | komm Reservdelslista</span>
                    <MdOutlineFileDownload />
                  </a>
                </DownloadButton>
              </DownloadList>
            </ul>
          </ProductDocument>
        </ProductInfo>
      </ProductSection>
    </main>
  );
};

export default PortKomm;
