import React from "react";
import { useTranslation } from 'react-i18next';

/* ---------------------------------------------------------------------------- */

import {
  NewsletterSection,
  NewsletterButton,
  NewsletterForm,
  Label,
  Input
} from "./StyledComponents";

/* ============================================================================ */
/*       NEWSLETTER - Function
/* ============================================================================ */


const Newsletter = () => {
  const { t } = useTranslation(); 

  return (
    <NewsletterSection>
    <NewsletterForm method="POST" action="https://gansub.com/s/NtYppfzCXkx4x/">

      <Label for="email">Email</Label>
      <Input type="text" id="email" placeholder="Email" name="email" />

      <Label for="first_name">Förnamn</Label>
      <Input type="text" id="first_name" placeholder="Förnamn" name="first_name" />

      <Label for="last_name">Efternamn</Label>
      <Input type="text" id="last_name" placeholder="Efternamn" name="last_name" />

      <Input type="hidden" name="gan_repeat_email" />
      <NewsletterButton type="submit" >
        <span>Prenumerera på nyhetsbrev</span>
      </NewsletterButton>
    </NewsletterForm>
    </NewsletterSection>
  )

};

export default Newsletter;