import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [session, setSession] = useState(null);
  const [authenticated, setAuthenticated] = useState(false);

  
  useEffect(() => {
    // Load user and session data from localStorage on component mount
    const storedUser = localStorage.getItem('user');
    const storedSession = localStorage.getItem('session');
    
    if (storedUser) {
      setUser(JSON.parse(storedUser));
      setAuthenticated(true);
    }
    
    if (storedSession) {
      setSession(JSON.parse(storedSession));
    }

  }, []);


  const checkLoggedIn = async () => {
    console.log("checking...");
    try {
      const response = await axios.get('/loggedIn');
      const data = response.data;
      setSession(data);
      if (data.passport) {
        setUser(data.passport.user);
        setAuthenticated(true);
        // Store user and session data in localStorage
        localStorage.setItem('user', JSON.stringify(data.passport.user));
        localStorage.setItem('session', JSON.stringify(data));;
        localStorage.setItem('authenticated', true);;
      } else {
        setSession(null);
        setUser(null);
        setAuthenticated(false);
        // Clear user and session data from localStorage
        localStorage.removeItem('user');
        localStorage.removeItem('session');
        localStorage.setItem('authenticated', false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <UserContext.Provider value={{ user, session, authenticated, checkLoggedIn }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  return useContext(UserContext);
};
 