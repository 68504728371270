//import { Link } from "react-router-dom";
import styled from "styled-components";

/* ============================================================================ */
/*          STYLED - Styled Components
/* ============================================================================ */

export const LoginSection = styled.section`
    overflow-x: hidden;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30%;
    text-align: center;
    width: 70%;
    padding: 10px;
    font-size: large;
    border: solid #212121 1px;
    box-shadow: 10px 10px;
    background-color: white;

    @media screen and (max-width: 690px) {
        width: 80%;
    }
`;

export const LoginButton = styled.button`
    width: 50%;
    color: white;
    padding: 2%;
    margin-top: 30px;
    font-size: x-large;
    border-radius: 10px;
    background-color: blue;

    @media screen and (max-width: 690px) {
      width: 80%;
      font-size: large;
  }
`;

export const FormDiv = styled.div`

& .error-message {
  font-size: large;
  padding: 4px;
  margin-top: 5%;
  color: red;
}

& .override {
  display: block;
  margin: 0 auto;

}

& .error-border {
  border: 1px solid red;
}

`;

export const Form = styled.form`
    padding: 40px;

    // animation styles
    transition: transform 0.3s ease-in-out;
  
    &.shake {
      animation: shake 0.5s ease;
    }
  
    @keyframes shake {
      0% {
        transform: translateX(0);
      }
      25% {
        transform: translateX(-5px);
      }
      50% {
        transform: translateX(5px);
      }
      75% {
        transform: translateX(-5px);
      }
      100% {
        transform: translateX(5px);
      }
    }
`;

export const Label = styled.label`
    padding: 10px;
    width: 100%;
`;

export const Input = styled.input`
    padding: 10px;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
`;

