import styled from "styled-components";
import { Link } from "react-router-dom";

/* ============================================================================ */
/*          STYLED - Styled Components
/* ============================================================================ */

export const Nav = styled.div`
  position: fixed;
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 690px) {
    justify-content: center;
  }

`;

export const NavLeft = styled.div`
  display: flex;
  align-items: center;
`;

export const NavRight = styled.div`
display: flex;
align-items: center;
position: relative;

.navOpen {
  
  transform: translateX(-180%);
  transition: transform 400ms ease;

  @media screen and (max-width: 690px) {
    transform: translateX(-200%);
  }
}

.navClosed {
  transform: translateX(0px);
  transition: transform 350ms ease;
}
`;

export const NavLogo = styled.img`
  padding-left: 2rem;
  height: 70px;
`;

export const NavIcon = styled(Link)`
  margin-right: 2rem;
  font-size: 2rem;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const FaBarsText = styled.p`
  font-size: var(--normal-font-size);
  font-weight: var(--font-semi-bold);
`;

export const NavClose = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 0.5rem;
`;

export const SidebarNav = styled.nav`
  background-color: rgba(253, 253, 253, 0.9);
  width: 250px;
  height: 100vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column; /* Set the flex direction to column */
  position: fixed;
  top: 0;
  right: ${({ sidebar }) => (sidebar ? "0" : "-100%")};
  transition: 350ms;
  z-index: var(--z-modal);

  ::-webkit-scrollbar {
    background-color: transparent;
    width: .5rem;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(100, 100, 100, 0.9);
    border-radius: 1rem;
  }
`;

export const SidebarWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const TopSection = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1; /* This will make it take the available space */
`;

export const BottomSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20%;
`;

export const LoginButton = styled.button`
  width: 100%;
  padding: 1rem;
  background-color: transparent;
`;

export const LoginLink = styled(Link)`
  padding: 1rem;
  padding-inline: 4rem;
  background-color: var(--icon-color);
  color: var(--nav-color);
  border-radius: 2rem;
  font-size: var(--h2-font-size);
`;

export const Call = styled.div`
  padding: 5rem 0 3rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CallUs = styled.p`
  font-size: var(--h3-font-size) !important;
  font-weight: var(--font-medium);
  text-align: center;

  b {
    font-size: var(--h2-font-size);
  }
`;
