import { useTranslation } from 'react-i18next';

/* ----------------------------------------------------------------------------
 *      IMAGES
 * --------------------------------------------------------------------------- */

import Veronica from "../../../data/images/personals/veronica.png";
import Niklas from "../../../data/images/personals/niklas.png";
import Markus from "../../../data/images/personals/markus.png";
import Daniel from "../../../data/images/personals/daniel.png";
import Katarina from "../../../data/images/personals/katarina.png";
import Leo from "../../../data/images/personals/leo.png";
import Valter from "../../../data/images/personals/valter.png";
import Jonas from "../../../data/images/personals/jonas.png";
import Rebecca from "../../../data/images/personals/rebecca.png";

/* ---------------------------------------------------------------------------- */

export const PersonalData = [

  {
    image: Veronica,
    name: "Veronica Pettersson",
    t_name: "veronica",
    number: "073-654 58 07",
    title: "Samordnare",
    info: " som bokar in alla uppdrag som kommer in till oss. Om du har blivit uppringd eller fått brev av oss är det jag som har sökt dig. Du kan komma i kontakt med mig på vårt växelnummer, eller via kontaktformuläret på kontakta-oss sidan.",
    hobby: "På fritiden: Utförsåkning är ett stående inslag varje säsong. Mellan säsongerna så förbereds det inför nästa säsong med hård träning, research och planering."
  },
  {
    image: Niklas,
    name: "Niklas Lööv",
    t_name: "niklas",
    number: "070-772 83 15",
    title: "Tekniker",
    info: " som löser alla krångliga problem med ett leende. Installerar, felsöker och servar både analoga och digitala hjälpmedel hemma hos patienter. Talar svenska, engelska och värmländska flytande. Kan grunderna i svenskt teckenspråk.",
    hobby: "På fritiden: Brygger norra Stockholms bästa Ale och ibland blir det även någon Ipa och äppel cider."
  },
  {
    image: Markus,
    name: "Markus Ström",
    t_name: "markus",
    number: "070-497 39 71",
    title: "Tjänste & Produktutvecklare",
    info: " med lång erfarenhet av hjälpmedel, speciellt inom området kommunikationshjälpmedel. Har varit med och arbetat i Sverige och andra länder för nationella hjälpmedelstjänster. Tycker att förutsättningarna för smarta, enkla hjälpmedel aldrig tidigare har varit så goda som nu.",
    hobby: "På fritiden: Gillar att tillbringa tid ute på sjön för att fånga den listiga Gösen. Det är en svårfångad fisk som kräver envishet och en hel del list."
  },
  {
    image: Daniel,
    name: "Daniel Ström",
    t_name: "daniel",
    number: "070-049 45 50",
    title: "Säljare & Tekniker",
    info: " med längst erfarenhet av hjälpmedel på företaget. Har arbetat med hörsel/syn och kognitionshjälpmedel i många år. Arbetat nära förskrivare och väl insatt i processen bakom ett hjälpmedel. HMC-Certifierad besiktningsman.",
    hobby: "På fritiden: Biodlare som har stor respekt för den fantastiska mekanism som ett bisamhälle uppvisar. Det består av 60-80.000 bin som samarbetar friktionsfritt, alla har sin uppgift och vet vad målet är. Det är imponerande!."
  },
  {
    image: Katarina,
    name: "Katarina Ström",
    t_name: "katarina",
    title: "Business Controller",
    info: " som arbetar med analyser, kommentarer och sammanställningar över den operationella utvecklingen. Med god koll på marknaden och hur den utvecklas kan vår position på densamma optimeras. Vi vill inte driva runt utan tydliga mål, mitt jobb är att hitta rätt mål och den strategi som tar oss dit. Arbetar även med kvalitetsuppföljning och corporate compliance.",
    hobby: "På fritiden: Tycker om att studera konst. Är en allätare som kan njuta lika mycket av en surrealistisk målning av Salvador Dali som av klassiska engelska naturmålningar från Bristol School of Artists."
  },
  {
    image: Leo,
    name: "Leo Thelin",
    t_name: "leo",
    title: "Lagerarbetare & Produktionsmedarbetare",
    info: " som håller koll på lagersaldo, gör mottag och förbereder försändelser ut till våra kunder. Arbetar även en del med rekonditionering av produkter och fungerar som facility manager för våra lokaler.",
    hobby: "På fritiden: Bygger gärna olika modeller och har ett stort intresse för båtar. Är också intresserade av klassiska filmer, gärna Bollywood."
  },
  {
    image: Valter,
    name: "Valter Askervall",
    title: "Tekniker",
    t_name: "valter",
    info: " som efter 100 år inom telekom nu rekonditionerar, felsöker och lagar de flesta analoga och digitala elektroniska hjälpmedel som finns på marknaden.",
    hobby: "På fritiden: Tillbringar mycket tid i den egna trä/metall verkstaden där praktiska vardagsföremål med en annorlunda twist produceras."
  },
  {
    image: Jonas,
    name: "Jonas Mäki",
    t_name: "jonas",
    number: "076-022 45 26",
    title: "Tekniker",
    info: " som arbetat med elscotrar och har erfarenhet av anpassningar inom privatbostäder. Arbetar med installation, service och reparation av både med äldre och nyare hjälpmedel. Kan utöver svenska även finska. HMC-Certifierad besiktningsman.",
    hobby: 'På fritiden: Spelar gärna frisbeegolf, eller "discgolf" som det egentligen heter, på någon av de banor som finns runt Stockholm.'
  },
  {
    image: Rebecca,
    name: "Rebecca Lööv",
    t_name: "rebecca",
    title: "Samordnare & Besiktningstekniker",
    info: " som tillsammans med Veronica håller koll på alla uppdrag som utförs och även besiktar sängar på vårdboenden runt om i Stockholm.",
    hobby: "På fritiden: En Norrlandspatriot som när tillfälle ges gärna berättar om hur bra Norrland är. Utöver Norrland så är även sport & handarbete intressen."
  },

];


export const GetTranslatedPersonalData = () => {
  const { t } = useTranslation("about/team"); // Provide the correct namespace

  return PersonalData.map(item => ({
    ...item,
    title: item.title ? t(`${item.t_name}_title`) : "",
    info: item.info ? t(`${item.t_name}_info`) : "",
    hobby: item.hobby ? t(`${item.t_name}_hobby`) : "",
  }));
};
