import React from "react";
import ProductInfo from "./ProductInfo";
import { MdOutlineFileDownload } from "react-icons/md";
import { useTranslation } from 'react-i18next';

/* ---------------------------------------------------------------------------- */

import {
  DownloadButton,
  DownloadList,
  ProductDescription,
  ProductDocument,
  ProductImage,
  ProductSection,
} from "./StyledComponents";

/* ----------------------------------------------------------------------------
 *          IMAGES
 * --------------------------------------------------------------------------- */

import TalKommPad from "../../../../data/images/products/Tal Komm/tal-komm_pad.png";

/* ----------------------------------------------------------------------------
 *          DOWNLOAD FILES
 * --------------------------------------------------------------------------- */

import TalKommProduktblad from "../../../../data/downloads/tal-komm_produktblad.pdf";
import TalKommManual from "../../../../data/downloads/tal-komm_manual.pdf";

/* ============================================================================ */
/*          TAL | KOMM - function
/* ============================================================================ */

const TalKomm = () => {  
  const { t } = useTranslation("products/talkomm"); 

  return (
    <main className="product container">
      <ProductSection>
        <h1>{t('h1_talkomm')}</h1>
        <ProductImage src={TalKommPad} alt="" />

        <ProductDescription>
          <p>{t('p1_talkomm')}</p>
          <p>{t('p2_talkomm')}</p>
          <p>{t('p3_talkomm')}</p>
          <span>
            <h3>{t('h3_1_talkomm')}</h3>
            <ul>
              <li>{t('li1_talkomm')}</li>
              <li>{t('li2_talkomm')}</li>
              <li>{t('li3_talkomm')}</li>
              <li>{t('li4_talkomm')}</li>
              <li>{t('li5_talkomm')}</li>
              <li>{t('li6_talkomm')}</li>
              <li>{t('li7_talkomm')}</li>
            </ul>
          </span>

          <h3>{t('h3_2_talkomm')}</h3>
          <ul>
            <li>tal | komm  - {t('artikelnr_talkomm')}: 1940</li>
            <li>tal | komm  10" - {t('artikelnr_talkomm')}: 1940-10</li>
          </ul>
        </ProductDescription>

        <ProductInfo titel={t('dokument_talkomm')}>
          <ProductDocument>
            <ul>
              <p>{t('laddaner_talkomm')}</p>

              <DownloadList>
                <DownloadButton>
                  <a href={TalKommProduktblad} download="TalKomm_Produktblad.pdf">
                    <span>tal | komm  Produktblad</span>
                    <MdOutlineFileDownload />
                  </a>
                </DownloadButton>
              </DownloadList>

              <DownloadList>
                <DownloadButton>
                  <a href={TalKommManual} download="TalKomm_Manual.pdf">
                    <span>tal | komm  Manual</span>
                    <MdOutlineFileDownload />
                  </a>
                </DownloadButton>
              </DownloadList>
            </ul>
          </ProductDocument>
        </ProductInfo>
      </ProductSection>
    </main>
  );
};

export default TalKomm;
