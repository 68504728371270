import React from "react";
import { useTranslation } from 'react-i18next';

/* ---------------------------------------------------------------------------- */

import {
  ContactButton,
  ContactDescription,
  ContactDetails,
  ContactHeader,
  ContactHeaderImage,
  ContactInfo,
  ContactSubTitle,
  ContactTitle,
} from "./StyledComponents";

import { MdOutlineEmail } from "react-icons/md";

/* ----------------------------------------------------------------------------
 *          IMAGES
 * --------------------------------------------------------------------------- */

import Header from "../../../data/images/kontakta-oss.png";

/* ============================================================================ */
/*       Contact - Function
/* ============================================================================ */

const Contact = () => {
  const { t } = useTranslation("contact"); 

  return (
    <main className="contact container">
      <ContactHeader>
        <ContactHeaderImage src={Header} alt="" />
        <ContactTitle>{t('h1_contact')}</ContactTitle>
      </ContactHeader>

      <ContactDescription>
        <h2>{t('h2_1_contact')}</h2>
        <br />
        <p>{t('p1_contact')} <b>{t('p1_bold_contact')}</b> {t('p1_2_contact')}</p>
      </ContactDescription>

      <ContactInfo>
        <ContactSubTitle>{t('h2_2_contact')}</ContactSubTitle>
        <h3>{t('h3_1_contact')}</h3>
        <p>{t('p2_contact')}</p>
        <p>{t('p3_contact')}</p>
        <br />
        <h3>{t('h3_2_contact')}</h3>
        <p>{t('p4_contact')}</p>
        <p>{t('p5_contact')}</p>
      </ContactInfo>

      <ContactDetails>
        <h3>{t('h3_3_contact')}</h3>
        <p><i>{t('p6_contact')}</i></p>
        <p>{t('p7_contact')}</p>
      </ContactDetails>
      <ContactButton>
        <a href="mailto:order@brstrom.se">
          <MdOutlineEmail />
          {t('a_contact')}
        </a>
      </ContactButton>
    </main>
  );
};

export default Contact;
