import React from "react";
import styled from "styled-components";
import { withTranslation } from 'react-i18next';

/* ============================================================================ */
/*          STYLED - Styled Components
/* ============================================================================ */

//const { t } = useTranslation("error"); 

const ErrorPage = styled.main`
    margin-top: 150px;
    text-align: center;
    margin-bottom: 10rem;
`

const ErrorH1 = styled.h1`
  margin-bottom: 2.5rem;
`;

const ErrorP = styled.p`
    font-size: var(--h3-font-size);
    padding: 1rem;
`;

const ErrorSpan = styled.span`
    font-size: var(--h2-font-size);
    font-weight: bold;
`;

/* ============================================================================ */
/*       Error - Function
/* ============================================================================ */

class NotFoundPage extends React.Component {
  render() {
    const { t } = this.props;

    return (
      <ErrorPage>
        <ErrorH1>{t('h1_error')}</ErrorH1>
        <ErrorP>{t('p1_error')}</ErrorP>
        <ErrorSpan>{t('span_error')}</ErrorSpan>
        <ErrorP>{t('p2_error')}</ErrorP>
      </ErrorPage>
    );
  }
}

export default withTranslation("error")(NotFoundPage);
