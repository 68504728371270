import styled from "styled-components";

/* ============================================================================ */
/*          STYLED - Styled Components
/* ============================================================================ */

export const AboutHeader = styled.header`
padding: 1.5rem;
    margin-top: 150px;
`;

export const AboutDescription = styled.div`
    padding-top: 1.5rem;
    line-height: 1.5;

    p {
        padding-bottom: 1rem;
    }
`;

export const AboutSection = styled.section`
    padding-top: 1.5rem;
    margin-bottom: 2.5rem;
    width: 80%;

    @media screen and (max-width: 1023) {
        width: auto;
    }

    @media screen and (max-width: 768px) {
        h2 {
            text-align: center;
            font-size: var(--h1-font-size);
        }
    }
`;

export const AboutTeam = styled.div`
    padding-top: 1rem;
`;

export const TeamCards = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    gap: 1.5rem;
`;

export const PersonalCard = styled.article`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.5rem 0 1.5rem;

    @media screen and (max-width: 768px) {
        display: grid;
        justify-items: center;
        gap: 1rem;
    }
`;

export const PersonalImage = styled.img`
    width: 262px;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 10px;
`;

export const PersonalText = styled.div`
    padding-left: 1.5rem;
`;

export const PersonalName = styled.h2`
    padding-bottom: 0.5rem;

    @media screen and (max-width: 768px) {
        text-align: center;
        font-size: var(--h2-font-size);
    }
`;

export const PersonalNumber = styled.h3`
    padding-bottom: 1.5rem;

    @media screen and (max-width: 768px) {
        text-align: center;
        font-size: var(--h3-font-size);
    }
`;

export const PersonalWork = styled.p`
    line-height: 1.5;
`;

export const PersonalHobby = styled.p`
    line-height: 1.5;
    padding-top: 1rem;
`;
