import styled from "styled-components";

/* ============================================================================ */
/*          STYLED - Styled Components
/* ============================================================================ */

export const ServicesHeader = styled.header`
  display: grid;
  grid-template-columns: 70% 30%;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 0 2.5rem;

  @media screen and (max-width: 660px) {
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding: 0 2.5rem;
  }
`;

export const ServicesHeaderImage = styled.img`
  position: relative;
  top: -130px;
  left: -400px;
  width: 900px;
  border-radius: 31% 69% 39% 61% / 41% 46% 54% 59%;
  padding: 1.5rem;
  z-index: -1;

  @media screen and (max-width: 890px) {
    top: -80px;
    left: -150px;
    width: 500px;
  }

  @media screen and (max-width: 660px) {
    position: relative;
    top: -50px;
    left: -100px;
    width: 400px;
  }
`;

export const ServicesHeaderText = styled.div`
  position: relative;
  top: -75px;
  right: 100px;
  line-height: 1.5;
  z-index: -1;
  
  h1 {
    width: 300px;
    padding-bottom: 1rem;
    font-size: var(--h1-font-size);
    font-weight: var(--font-medium);
  }
  
  h3 {
    font-size: var(--h3-font-size);
    font-weight: var(--font-normal);
    text-align: center;
  }
  
  @media screen and (max-width: 890px) {
    top: -5px;
    right: 75px;
  }
  
  @media screen and (max-width: 660px) {
    position: relative;
    top: -75px;
    right: -70px;
    width: 250px;
    
    h1 {
      width: 200px;
    }
    
    h3 {
      font-size: var(--normal-font-size);
      font-weight: var(--font-medium);
      text-align: left;
      margin-bottom: -1.5rem;
    }
  }
  `;

export const ServicesSection = styled.section`
  position: relative;
  top: -100px;
  
  @media screen and (max-width: 890px) {
    top: -50px;
  }

  @media screen and (max-width: 660px) {
    padding: 0;
  }
  
  @media screen and (max-width: 350px) {
    display: flex;
    flex-wrap: wrap;
  }
  `;

export const ServicesDescription = styled.p`
  padding: 1rem 3rem 0rem 3rem;
  font-size: var(--normal-font-size);
  line-height: 1.5;
  `;

export const ServicesArticles = styled.div`
  padding: 0 3rem;
  display: grid;
  margin-top: 1rem;
  line-height: 1.5;
  
  @media screen and (max-width: 350px) {
    padding: 0 1rem;
    margin: 0;
  }
`;

export const ServicesArticle = styled.article`
  margin: 1rem 0 1rem 0;

  h2 {
    padding: 1rem 0 0.5rem 0;
    font-size: var(--h2-font-size);
  }

  h3 {
    padding: 1.5rem 0 0.5rem 0;
    font-size: var(--h3-font-size);
    font-weight: var(--font-medium);
  }

  @media screen and (max-width: 660px) {
    margin: 0;
  }
`;