import styled from "styled-components";

export const StyledDesktop = styled.div`
background-color: #efeffa;
max-width: 1440px;
margin: 0 auto;

display: flex;
align-items: center; 
justify-content: center;


@media (min-width: 1800px) {
  max-width: 1600px;
}


& .content {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 20px;
  width: 100%;
  max-width: 1008px;
  text-align: center;
  margin-top: 20%;
  align-items: center;

  @media (max-width: 768px) {
    margin-top: 40%;
    gap: 20px;
  }

  @media (min-width: 1800px) {
    max-width: 1600px;
  }

}

& .list {
  align-items: center; 
  background-color: #efeffa;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 0;
  position: relative;
  width: 100%;
  max-width: 1008px;

  @media (max-width: 768px) {
    gap: 10px; 
  }

  @media (min-width: 1800px) {
    max-width: 1600px;
  }

}

& .frame {
  display: flex;
  gap: 10px;
  justify-content: center;
  width: 100%;

  @media (max-width: 768px) {
    gap: 5px;
  }
}

& .text-wrapper {
  font-size: 24px;
  width: 100%;

  @media (max-width: 768px) {
    font-size: 20px; 
  }
}

& .row, & .row-2, & .row-3, & .row-4 {
  display: flex;
  justify-content: space-between; 
  align-items: center;
  gap: 10px; 
  width: 100%;

  @media (max-width: 768px) {
    gap: 5px; 
  }
}

& .div {
  font-size: xx-large;
  width: fit-content;

  @media (max-width: 768px) {
    font-size: 18px; 
  }
}

& .heroicons-solid {
  height: 40px;
  width: 40px;

  @media (max-width: 768px) {
    height: 24px; 
    width: 24px;
  }
}

& .heroicons-dropdown {
  height: 32px;
  width: 32px;
  float: right;
  margin-left: 10px;

  @media (max-width: 768px) {
    height: 20px; 
    width: 20px;
  }
}

& .dropdown {
  display: flex;
  align-items: center;
  padding: 8px;
  justify-content: center;
  margin-top: 2%;
}

& .dropdownRow {
  width: 100%;
  font-size: x-large;

  @media (max-width: 768px) {
    font-size: 16px; 
  }
}

& .paragraph {
  float: left;
}

`;


export const Line = styled.hr`

  border: 1px;
  height: 1px;
  width: 100%;
  color: black;
  background-color: currentColor;
`;

export const Title = styled.h1`

  font-size: xxx-large;
  margin-bottom: 10%;

  @media (max-width: 768px) {
    font-size: xx-large;
  }
  
`;

export const DropdownLine = styled.hr`

border: 1px;
height: 1px;
width: 60%;
color: black;
background-color: currentColor;
margin: 0 auto;

`;