import React from "react";
import styled from "styled-components";
import { useTranslation } from 'react-i18next';

/* ----------------------------------------------------------------------------
 *          IMAGES
 * --------------------------------------------------------------------------- */

import BrStrom from "../../data/images/logos/brstrom-logo-light.png";
import ElKretsen from "../../data/images/logos/el-kretsen.png";
import Naturvardsverket from "../../data/images/logos/naturvardsverket.png";
import Näringslivets from "../../data/images/logos/naringslivets.svg";

/* ============================================================================ */
/*          STYLED COMPONENTS
/* ============================================================================ */

const FooterContent = styled.footer`
    position: relative;
    bottom: 0;
    width: 100%;
    background: var(--text-color);
    margin-top: 4rem !important;

    @media screen and (max-width: 690px) {
        position: relative;
    }
`;

const FooterGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(1, 3fr);
`;

const FooterLogo = styled.img`
    display: flex !important;
    justify-self: center;
    margin-top: 2.5rem;
    width: 200px;
`;

const Companies = styled.div`
    display: grid;
    grid-template-columns: repeat(1, 3fr);
    margin-top: 3rem;
    color: #FFF;
    text-align: center;

    @media screen and (max-width: 480px) {
        margin-bottom: 1rem;
    }
`;

const CompaniesLogo = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;

    img {
        margin: 1rem 0 2rem 0;
        height: 50px;
        max-width: 200px;
    }

    @media screen and (max-width: 480px) {
        display: flex;
        margin: 0 0 2rem 0;

        img {
            margin: 0;
            height: 30px;
        }
    }
`;

/* ============================================================================ */
/*          FOOTER - Function
/* ============================================================================ */

const Footer = () => {
    const { t } = useTranslation("footer"); 

    return (
        <FooterContent>
            <FooterGrid>
                <FooterLogo src={BrStrom} alt="Br Ström" />
            <Companies className="container">
                    {t('footer_text')}
                    <CompaniesLogo>
                        <img src={Näringslivets} alt="Näringslivets Producentansvar" />
                        <img src={ElKretsen} alt="El-Kretsen" />
                        <img src={Naturvardsverket} alt="Naturvårdsverket" />
                    </CompaniesLogo>
                </Companies>
            </FooterGrid>
        </FooterContent>
    );
};

export default Footer;

/* ============================================================================ */
/*          STYLED - Styled Components
/* ============================================================================ */
