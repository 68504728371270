import React from "react";
import ProductInfo from "./ProductInfo";
import { MdOutlineFileDownload } from "react-icons/md";
import { useTranslation } from 'react-i18next';

/* ---------------------------------------------------------------------------- */

import {
  DownloadButton,
  DownloadList,
  ProductDescription,
  ProductDocument,
  ProductImage,
  ProductSection,
} from "./StyledComponents";

/* ----------------------------------------------------------------------------
 *          IMAGES
 * --------------------------------------------------------------------------- */

import ProductTKPro from "../../../../data/images/products/Tydlig Kalender Pro/product_tk_pro.png";

/* ----------------------------------------------------------------------------
 *          DOWNLOAD FILES
 * --------------------------------------------------------------------------- */

import TKP_Produktblad from "../../../../data/downloads/tydlig-kalender-produktblad.pdf";
import TKP_Snabbguide from "../../../../data/downloads/tydlig-kalender-pro-snabbguide_SV.pdf";
import TKP_Manual from "../../../../data/downloads/tydlig-kalender-pro-manual_SV.pdf";

/* ============================================================================ */
/*          TYDLIG KALENDER PRO - function
/* ============================================================================ */

const TydligKalenderPro = () => {  
  const { t } = useTranslation("products/tkPro"); 

  return (
    <main className="product container">
      <ProductSection>
        <h1>{t('h1_tkPro')}</h1>
        <ProductImage src={ProductTKPro} alt="" />

        <ProductDescription>
          <p>{t('p1_tkPro')}</p>
          <p>{t('p2_tkPro')}</p>
          <p>{t('p3_tkPro')}</p>
          <p>{t('p4_tkPro')}</p>

          <h3>{t('h3_tkPro')}</h3>
          <ul>
            <li>Pro 10" - {t('artikelnr_tkPro')}: 9011-10</li>
            <li>Pro 13" - {t('artikelnr_tkPro')}: 9011-13</li>
          </ul>
        </ProductDescription>

        <ProductInfo titel={t('dokument_tkPro')}>
          <ProductDocument>
            <ul>
              <p>{t('laddaner_tkPro')}</p>

              <DownloadList>
                <DownloadButton>
                  <a
                    href={TKP_Produktblad}
                    download="TydligKalender_Produktblad.pdf"
                  >
                    <span>tydlig | kalender Produktblad</span>
                    <MdOutlineFileDownload />
                  </a>
                </DownloadButton>
              </DownloadList>

              <DownloadList>
                <DownloadButton>
                  <a
                    href={TKP_Snabbguide}
                    download="TydligKalenderPro_Snabbguide.pdf"
                  >
                    <span>tydlig | kalender Pro Snabbguide</span>
                    <MdOutlineFileDownload />
                  </a>
                </DownloadButton>
              </DownloadList>

              <DownloadList>
                <DownloadButton>
                  <a
                    href={TKP_Manual}
                    download="TydligKalenderPro_Manual.pdf"
                  >
                    <span>tydlig | kalender Pro Manual</span>
                    <MdOutlineFileDownload />
                  </a>
                </DownloadButton>
              </DownloadList>
            </ul>
          </ProductDocument>
        </ProductInfo>
      </ProductSection>
    </main>
  );
};

export default TydligKalenderPro;
