
import styled from "styled-components";

/* ============================================================================ */
/*          STYLED - Styled Components
/* ============================================================================ */

export const NewsletterForm = styled.form`
  padding: 5%;
`;

export const NewsletterSection = styled.section`
    overflow-x: hidden;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30%;
    text-align: center;
    width: 70%;
    padding: 10px;
    font-size: large;
    border: solid #212121 1px;
    box-shadow: 10px 10px;
    background-color: white;

    @media screen and (max-width: 690px) {
        width: 80%;
    }
`;

export const NewsletterButton = styled.button`
    width: 50%;
    color: white;
    padding: 2%;
    margin-top: 30px;
    font-size: x-large;
    border-radius: 10px;
    background-color: blue;

    @media screen and (max-width: 690px) {
      width: 80%;
      font-size: large;
  }
`;

export const Label = styled.label`
    padding: 10px;
    width: 100%;
    display: flex;
    justify-content: center;
`;

export const Input = styled.input`
    padding: 10px;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
`;
