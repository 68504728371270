import { Link } from "react-router-dom";
import styled from "styled-components";

/* ============================================================================ */
/*          STYLED - Styled Components
/* ============================================================================ */

export const ProductsHeader = styled.header`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2.5rem;
`;

export const ProductsHeaderText = styled.div`
    margin-top: 150px;
    width: 300px;
    line-height: 1.5;
    text-align: center;

    h1 {
        font-size: var(--h1-font-size);
        font-weight: var(--font-medium);
    }

    h3 {
        font-size: var(--h3-font-size);
        font-weight: var(--font-normal);
    }
`;

export const ProductsHeaderImage = styled.img`
    margin-top: 100px;
    width: 500px;
    border-radius: 70% 30% 40% 50% / 40% 40% 60% 50%;

    @media screen and (max-width: 1023px) {
        width: 400px;
    }

    @media screen and (max-width: 768px) {
        display: none;
    }
`;

export const ProductsSection = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2.5rem 0 5rem;
`;

export const ProductsDescription = styled.div`
    font-size: var(--normal-font-size);
    line-height: 1.5;
    width: 80%;

    p {
        padding-bottom: 1rem;
    }

    @media screen and (max-width: 1023px) {
        width: 80%;
    }

    @media screen and (max-width: 768px) {
        width: 80%;
    }
`;

export const ProductsArticles = styled.div``;

export const ProductsArticle = styled.article`
    margin-top: 1.5rem;
    display: grid;
    grid-template-columns: 30% 60%;
    gap: 5rem;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 1023px) {
        grid-template-columns: 30% 50%;
    }

    @media screen and (max-width: 768px) {
        grid-template-columns: 30% 50%;
        align-items: flex-start;
        row-gap: 2rem;
    }

    @media screen and (max-width: 560px) {
        grid-template-columns: 1fr;
        align-items: center;
        justify-items: center;
        row-gap: 2rem;
        padding: 0 2rem;
    }
`;

export const ProductsArticleImage = styled.div``;

export const ProductsImage = styled.img`
    width: 300px;
    height: auto;
    padding-right: 1.5rem;

    @media screen and (max-width: 768px) {
        width: 200px;
        padding-top: 1.5rem;
    }

    @media screen and (max-width: 560px) {
        width: 250px;
        padding-top: 1.5rem;
    }
`;

export const ProductsArticleDescription = styled.div`
    font-size: var(--normal-font-size);
    line-height: 1.5;
    margin-top: 1rem;

    p {
        padding-top: 1rem;
    }

    @media screen and (max-width: 350px) {
        font-size: smaller;
        gap: 1rem;
        align-items: center;
    }
`;

export const ProductsLink = styled(Link)`
    display: flex;
    align-items: center;
    column-gap: 1rem;
    padding-top: 1rem;

    @media screen and (max-width: 560px) {
        span {
            font-size: var(--smaller-font-size);
        }
    }

    @media screen and (max-width: 350px) {
        span {
            font-size: var(--normal-font);
        }
    }
`;