import React from "react";
import ProductInfo from "./ProductInfo";
import { MdOutlineFileDownload } from "react-icons/md";
import { useTranslation } from 'react-i18next';

/* ---------------------------------------------------------------------------- */

import {
  DownloadButton,
  DownloadList,
  ProductDescription,
  ProductDocument,
  ProductImage,
  ProductSection,
  AccessImage,
  Accessories,
  ProductFlex,
} from "./StyledComponents";

/* ----------------------------------------------------------------------------
 *          IMAGES
 * --------------------------------------------------------------------------- */

import WellbeePadImage from "../../../../data/images/products/Wellbee Pad/wellbee-pad.png";
import Bordstativ from "../../../../data/images/products/Wellbee Pad/hz2870_12.png";
import Vaggstativ from "../../../../data/images/products/Wellbee Pad/hz2870_6.png";
import Arm from "../../../../data/images/products/Wellbee Pad/hz2870_8.png";
import Laddkabel from  "../../../../data/images/products/Wellbee Pad/Laddkabel.png";
import MjukVäska from  "../../../../data/images/products/Wellbee Pad/Mjuk-väska.png"

/* ----------------------------------------------------------------------------
 *          DOWNLOAD FILES
 * --------------------------------------------------------------------------- */

import WellbeeProduktblad from "../../../../data/downloads/wellbee-produktblad.pdf";
import FI_WellbeeProduktblad from "../../../../data/downloads/FI_Wellbee_Produktblad.pdf"
import NO_WellbeeManual from "../../../../data/downloads/NO_Brukermanual_wellbee.pdf"

/* ============================================================================ */
/*          TYDLIG KALENDER PRO - function
/* ============================================================================ */

const WellbeePad = () => {  
  const { t } = useTranslation("products/wellbeePad"); 

  return (
    <main className="product container">
      <ProductSection>
        <h1>{t('h1_wellbeePad')}</h1>
        <ProductImage src={WellbeePadImage} alt="" />

        <ProductDescription>
          <p>{t('p1_wellbeePad')}</p>
          <p>{t('p2_wellbeePad')}</p>
          <p>{t('p3_wellbeePad')}</p>
          <p>{t('p4_wellbeePad')}</p>
          <p>{t('p5_wellbeePad')}</p>
          <p>{t('p6_wellbeePad')}</p>
          <p>{t('p7_wellbeePad')}</p>
          <h3>{t('h3_wellbeePad')}</h3>
          <ul>
            <li>{t('artikelnr_wellbeePad')}: N8010-10</li>
          </ul>
        </ProductDescription>

        <ProductInfo titel={t('tillbehör_wellbee')} className="flex">
          <ProductFlex>
            <Accessories>
              <AccessImage
                src={Bordstativ}
                alt=""
              />
              <p>{t('tillbehör_del_1_wellbee')}</p>
            </Accessories>
            <Accessories>
              <AccessImage
                src={Vaggstativ}
                alt=""
              />
              <p>{t('tillbehör_del_2_wellbee')}</p>
            </Accessories>
          </ProductFlex>
          <ProductFlex>
          <Accessories>
              <AccessImage
                src={Arm}
                alt=""
              />
              <p>{t('tillbehör_del_3_wellbee')}</p>
            </Accessories>
            <Accessories>
              <AccessImage
                src={Laddkabel}
                alt=""
              />
              <p>{t('tillbehör_del_4_wellbee')}</p>
            </Accessories>
            <Accessories>
              <AccessImage
                src={MjukVäska}
                alt=""
              />
              <p>{t('tillbehör_del_5_wellbee')}</p>
            </Accessories>
          </ProductFlex>
        </ProductInfo>

        <ProductInfo titel={t('dokument_wellbeePad')}>
          <ProductDocument>
            <ul>
              <p>{t('laddaner_wellbeePad')}</p>

              <DownloadList>
                <DownloadButton>
                  <a href={WellbeeProduktblad} download="Wellbee_Produktblad.pdf">
                    <span>Wellbee Produktblad</span>
                    <MdOutlineFileDownload />
                  </a>
                </DownloadButton>

                <DownloadButton>
                  <a href={FI_WellbeeProduktblad} download="FI_Wellbee_Produktblad.pdf">
                    <span>Wellbee Produktblad På Finska</span>
                    <MdOutlineFileDownload />
                  </a>
                </DownloadButton>
                
                <DownloadButton>
                  <a href={NO_WellbeeManual} download="NO_Brukermanual_wellbee.pdf">
                    <span>Wellbee Manual På Norska</span>
                    <MdOutlineFileDownload />
                  </a>
                </DownloadButton>
              </DownloadList>
            </ul>
          </ProductDocument>
        </ProductInfo>
      </ProductSection>
    </main>
  );
};

export default WellbeePad;
