import React, { useState } from "react";

/* ---------------------------------------------------------------------------- */

import {
  Line, 
  StyledDesktop, 
  Title,
  DropdownLine 
} from "./StyledComponents.jsx";

/* ----------------------------------------------------------------------------
 *          DOCUMENTS
 * --------------------------------------------------------------------------- */

import Basic3APK from "../../../data/tekniker/Basic3.1.apk"
import Pro3APK from "../../../data/tekniker/Pro3.1.apk"
import DAVx5 from "../../../data/tekniker/DAVx5.apk"
import KalenderKlocka3APK from "../../../data/tekniker/Kalenderklocka3.1.apk"
import TalkommAPK from "../../../data/tekniker/TalKomm_20201030.apk"

import ÖppnaBatteri from "../../../data/tekniker/Öppna-Batteri-Valter.mov"
import SättaIBatteri from "../../../data/tekniker/Sätta-i-Batteri-Valter.mov"

import Bakgrund from "../../../data/tekniker/bakgr.png"

import TydligkalenderRekond from "../../../data/tekniker/TydligKalenderRekond.pdf"

/* ----------------------------------------------------------------------------
 *          IMAGES
 * --------------------------------------------------------------------------- */

import {
  ArrowDownTrayIcon, 
  ArrowRightIcon,
  PlayIcon,
  ArrowDownIcon
} from "@heroicons/react/24/outline";

/* ============================================================================ */
/*       Tekniker - Function
/* ============================================================================ */

export const Tekniker = () => {

  const [isVideoOpen, setIsVideoOpen] = useState(false);
  const [isAPKOpen, setIsAPKOpen] = useState(false);
  const [isManualsOpen, setIsManualsOpen] = useState(false);
  const [isOtherDocsOpen, setIsOtherDocsOpen] = useState(false);

  const toggleVideo = () => {
    setIsVideoOpen(!isVideoOpen);
  };

  const toggleAPK = () => {
    setIsAPKOpen(!isAPKOpen);
  };

  const toggleManuals = () => {
    setIsManualsOpen(!isManualsOpen);
  };

  const toggleOtherDocs = () => {
    setIsOtherDocsOpen(!isOtherDocsOpen);
  };

  return (
    <StyledDesktop>
      <div className="content">
        <div className="list">
          <div className="frame">
            <Title>
                <span>Dokument</span>
            </Title>
          </div>

          <Line />

          {isVideoOpen && (
            <div className="row" onClick={toggleVideo}>
              <div className="div">Videos</div>
              <ArrowDownIcon className="heroicons-solid" />
            </div>        
          )}   
          {!isVideoOpen && (
            <div className="row" onClick={toggleVideo}>
              <div className="div">Videos</div>
              <ArrowRightIcon className="heroicons-solid" />
            </div>       
          )}   
          {isVideoOpen && (
              <div className="dropdownRow">  
                <Line />              
                <div className="dropdown">
                  <a href={ÖppnaBatteri}>
                  <p className="paragraph">Öppna Batteri Valter</p>
                  <PlayIcon className="heroicons-dropdown" />
                  </a>
                </div>
              </div>          
          )}        
          {isVideoOpen && (
            <div className="dropdownRow">
              <DropdownLine />
              <div className="dropdown">
                <a href={SättaIBatteri}>
                  <p className="paragraph">Sätt i Nya Batteri Valter</p>
                  <PlayIcon className="heroicons-dropdown" />
                </a>
              </div>
            </div>
          )}

          <Line />

          {isAPKOpen && (
            <div className="row" onClick={toggleAPK}>
              <div className="div">APK</div>
              <ArrowDownIcon className="heroicons-solid" />
            </div>        
          )}   
          {!isAPKOpen && (
            <div className="row" onClick={toggleAPK}>
              <div className="div">APK</div>
              <ArrowRightIcon className="heroicons-solid" />
            </div>       
          )}   
          {isAPKOpen && (
              <div className="dropdownRow">  
              <Line />              
              <div className="dropdown">
                <a href={Basic3APK} download>
                  <p className="paragraph">Tydlig Kalender Basic 3.1</p>
                  <ArrowDownTrayIcon className="heroicons-dropdown"/>
                </a>
              </div>
            </div>            
          )}
          {isAPKOpen && (
            <div className="dropdownRow">
            <DropdownLine />
            <div className="dropdown">
              <a href={Pro3APK} download>
                  <p className="paragraph">Tydlig Kalender Pro 3.1</p>
                  <ArrowDownTrayIcon className="heroicons-dropdown"/>
              </a>
            </div>
          </div>
          )}          
          {isAPKOpen && (
            <div className="dropdownRow">
            <DropdownLine />
            <div className="dropdown">
              <a href={DAVx5} download>
                  <p className="paragraph">DAVx 5</p>
                  <ArrowDownTrayIcon className="heroicons-dropdown"/>
              </a>
            </div>
          </div>
          )} 
          {isAPKOpen && (
            <div className="dropdownRow">
            <DropdownLine />
            <div className="dropdown">
              <a href={KalenderKlocka3APK} download>
                  <p className="paragraph">Kalender Klocka 3.1</p>
                  <ArrowDownTrayIcon className="heroicons-dropdown"/>
              </a>
            </div>
          </div>
          )} 
          {isAPKOpen && (
            <div className="dropdownRow">
            <DropdownLine />
            <div className="dropdown">
              <a href={TalkommAPK} download>
                  <p className="paragraph">Tal Komm</p>
                  <ArrowDownTrayIcon className="heroicons-dropdown"/>
              </a>
            </div>
          </div>
          )}           

          <Line />

          {isManualsOpen && (
            <div className="row" onClick={toggleManuals}>
              <div className="div">Rekonditionerings Manualer</div>
              <ArrowDownIcon className="heroicons-solid" />
            </div>        
          )}   
          {!isManualsOpen && (
            <div className="row" onClick={toggleManuals}>
              <div className="div">Rekonditionerings Manualer</div>
              <ArrowRightIcon className="heroicons-solid" />
            </div>       
          )}  
          {isManualsOpen && (
              <div className="dropdownRow">  
              <Line />              
              <div className="dropdown">
                <a href={TydligkalenderRekond} download>
                <p className="paragraph">Rekonditionerings Manual Tydlig Kalender</p>
                <ArrowDownTrayIcon className="heroicons-dropdown"/>
                  </a>
              </div>
            </div>          
          )}

          <Line />

          {isOtherDocsOpen && (
            <div className="row" onClick={toggleOtherDocs}>
              <div className="div">Andra Dokument</div>
              <ArrowDownIcon className="heroicons-solid" />
            </div>        
          )}   
          {!isOtherDocsOpen && (
            <div className="row" onClick={toggleOtherDocs}>
              <div className="div">Andra Dokument</div>
              <ArrowRightIcon className="heroicons-solid" />
            </div>       
          )} 
          {isOtherDocsOpen && (
              <div className="dropdownRow">  
              <Line />              
              <div className="dropdown">
                <a href={Bakgrund} download>
                <p className="paragraph">Bakgrundsbild br|ström</p>
                <ArrowDownTrayIcon className="heroicons-dropdown"/>
                  </a>
              </div>
            </div>             
          )}

          <Line />

        </div>
      </div>
    </StyledDesktop>
  );
};

export default Tekniker;
