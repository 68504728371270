import React from "react";
import ProductInfo from "./ProductInfo";
import { MdOutlineFileDownload } from "react-icons/md";
import { useTranslation } from 'react-i18next';

/* ---------------------------------------------------------------------------- */

import {
  DownloadButton,
  DownloadList,
  ProductDescription,
  ProductDocument,
  ProductImage,
  ProductSection,
} from "./StyledComponents";

/* ----------------------------------------------------------------------------
 *          IMAGES
 * --------------------------------------------------------------------------- */

import ProductTKBasic from "../../../../data/images/products/Tydlig Kalender Basic/product_tk_basic.png";

/* ----------------------------------------------------------------------------
 *          DOWNLOAD FILES
 * --------------------------------------------------------------------------- */

import TKB_Produktblad from "../../../../data/downloads/tydlig-kalender-produktblad.pdf";
import TKB_Snabbguide from "../../../../data/downloads/tydlig-kalender-basic-snabbguide_SV.pdf";
import TKB_Manual from "../../../../data/downloads/tydlig-kalender-basic-manual_SV.pdf";

/* ============================================================================ */
/*          TYDLIG KALENDER BASIC - function
/* ============================================================================ */

const TydligKalenderBasic = () => {  
  const { t } = useTranslation("products/tkBasic"); 

  return (
    <main className="product container">
      <ProductSection>
        <h1>{t('h1_tkBasic')}</h1>
        <ProductImage src={ProductTKBasic} alt="" />

        <ProductDescription>
          <p>{t('p1_tkBasic')}</p>
          <p>{t('p2_tkBasic')}</p>
          <p>{t('p3_tkBasic')}</p>
          <p>{t('p4_tkBasic')}</p>
          <p>{t('p5_tkBasic')}</p>

          <h3>{t('h3_tkBasic')}</h3>
          <ul>
            <li>Basic 10" - {t('artikelnr_tkBasic')}: 9010-10</li>
            <li>Basic 13" - {t('artikelnr_tkBasic')}: 9010-13</li>
          </ul>
        </ProductDescription>

        <ProductInfo titel={t('dokument_tkBasic')}>
          <ProductDocument>
            <ul>
                <p>{t('laddaner_tkBasic')}</p>

              <DownloadList>
                <DownloadButton>
                  <a
                    href={TKB_Produktblad}
                    download="TydligKalender_Produktblad.pdf"
                  >
                    <span>tydlig | kalender Produktblad</span>
                    <MdOutlineFileDownload />
                  </a>
                </DownloadButton>
              </DownloadList>

              <DownloadList>
                <DownloadButton>
                  <a
                    href={TKB_Snabbguide}
                    download="TydligKalenderBasic_Snabbguide.pdf"
                  >
                    <span>tydlig | kalender Basic Snabbguide</span>
                    <MdOutlineFileDownload />
                  </a>
                </DownloadButton>
              </DownloadList>

              <DownloadList>
                <DownloadButton>
                  <a
                    href={TKB_Manual}
                    download="TydligKalenderBasic_Manual.pdf"
                  >
                    <span>tydlig | kalender Basic Manual</span>
                    <MdOutlineFileDownload />
                  </a>
                </DownloadButton>
              </DownloadList>

            </ul>
            
          </ProductDocument>
        </ProductInfo>
      </ProductSection>
    </main>
  );
};

export default TydligKalenderBasic;
