import React from "react";
import { useTranslation } from "react-i18next";

/* ---------------------------------------------------------------------------- */

import { BiHome, BiMessageAltDetail } from "react-icons/bi";
import { MdDevicesOther } from "react-icons/md";
import {
  RiArrowDownSFill,
  RiArrowUpSFill,
  RiServiceLine,
  RiTeamLine,
  RiToolsLine
} from "react-icons/ri";

/* ---------------------------------------------------------------------------- */

export const SidebarData = [
  {
    title: "Hem",
    t_name: "home",
    path: "/",
    icon: <BiHome />,
  },
  {
    title: "Produkter",
    t_name: "products",
    icon: <MdDevicesOther />,

    iconClosed: <RiArrowDownSFill />,
    iconOpened: <RiArrowUpSFill />,

    /* --- SubNavigation under Produkter --- */
    subNav: [
      {
        title: "Alla hjälpmedel",
        t_name: "hjalpmedel",
        path: "/produkter",
      },
      {
        title: "tk BASIC",
        path: "/produkter/tydlig-kalender-basic",
      },
      {
        title: "tk PRO",
        path: "/produkter/tydlig-kalender-pro",
      },
      {
        title: "tk Klocka",
        path: "/produkter/tydlig-kalender-klocka",
      },
      {
        title: "tal | komm",
        path: "/produkter/tal-komm",
      },
      {
        title: "port | komm",
        path: "/produkter/port-komm",
      },
      {
        title: "med | time",
        path: "/produkter/med-time",
      },
      {
        title: "wellbee APP",
        path: "/produkter/wellbee-app",
      },
      {
        title: "wellbee PAD",
        path: "/produkter/wellbee-pad",
      },
      {
        title: "wellbee WALL",
        path: "/produkter/wellbee-wall",
      },
      {
        title: "Valter",
        path: "/produkter/valter",
      },
    ],
  },
  /* ------------------------------- */
  {
    title: "Tjänster",
    t_name: "tjanster",
    path: "/tjanster",
    icon: <RiServiceLine />,
  },
  {
    title: "Om oss",
    t_name: "about",
    path: "/om",
    icon: <RiTeamLine />,
  },
  {
    title: "Kontakta oss",
    t_name: "contact",
    path: "/kontakt",
    icon: <BiMessageAltDetail />,
  },
  {
    title: "Tekniker",
    t_name: "tekniker",
    path: "/tekniker",
    icon: <RiToolsLine />,
    showOnlyIfAuthenticated: true,
  }
];

/*
export const GetTranslatedSidebar = () => {
  const { t } = useTranslation("sidebar"); // Provide the correct namespace

  return SidebarData.map(item => ({
    ...item,
    title: item.t_name ? t(`${item.t_name}_title`) : "",
  }));
};*/

export const GetTranslatedSidebar = () => {
  const { t } = useTranslation("sidebar"); // Provide the correct namespace

  return SidebarData.map(item => {
    const translatedItem = {
      ...item,
      title: item.t_name ? t(`${item.t_name}_title`) : "",
    };

    // Translate titles within the subNav array
    if (item.subNav) {
      translatedItem.subNav = item.subNav.map(subNavItem => ({
        ...subNavItem,
        title: subNavItem.t_name ? t(`${subNavItem.t_name}_title`) : subNavItem.title,
      }));
    }

    return translatedItem;
  });
};

