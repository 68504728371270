import React from "react";
import ProductInfo from "./ProductInfo";
import { MdOutlineFileDownload } from "react-icons/md";
import { useTranslation } from 'react-i18next';

/* ---------------------------------------------------------------------------- */

import {
  AccessImage,
  Accessories,
  DownloadButton,
  DownloadList,
  ProductDescription,
  ProductDocument,
  ProductImage,
  ProductSection,
} from "./StyledComponents";

/* ----------------------------------------------------------------------------
 *          IMAGES
 * --------------------------------------------------------------------------- */

import ProductTKKlocka from "../../../../data/images/products/Tydlig Kalender Klocka/simkort.png";
import KlockaLarge from "../../../../data/images/products/Tydlig Kalender Klocka/klocka-large.png";
import KlockaMedium from "../../../../data/images/products/Tydlig Kalender Klocka/singelklocka.png";
import TKKLaddare from "../../../../data/images/products/Tydlig Kalender Klocka/laddare.png";

/* ----------------------------------------------------------------------------
 *          DOWNLOAD FILES
 * --------------------------------------------------------------------------- */

import TKK_Produktblad from "../../../../data/downloads/tydlig-kalender-klocka-produktblad.pdf";
import FI_TKK_Produktblad from "../../../../data/downloads/FI_Kalender_Klocka_Produktblad.pdf";

/* ============================================================================ */
/*          TYDLIG KALENDER KLOCKA - function
/* ============================================================================ */

const TydligKalenderKlocka = () => {  
  const { t } = useTranslation("products/tkKlocka"); 

  return (
    <main className="product container">
      <ProductSection>
        <h1>{t('h1_tkKlocka')}</h1>
        <ProductImage src={ProductTKKlocka} alt="" />

        <ProductDescription>
          <p>{t('p1_tkKlocka')}</p>
          <p>{t('p2_tkKlocka')}</p>
          <p>{t('p3_tkKlocka')}</p>
          <p>{t('p4_tkKlocka')}</p>

          <h3>{t('h3_tkKlocka')}</h3>
          <ul>
            <li>
              <img
                src={KlockaMedium}
                alt=""
                style={{
                  width: "70px",
                  paddingBottom: "1rem",
                  display: "grid",
                }}
              />
              MEDIUM
              <br />
              {t('artikelnr_tkKlocka')}: TKK 182-00
            </li>
            <li>
              <img
                src={KlockaLarge}
                alt=""
                style={{ width: "100px", padding: "1rem 0", display: "grid" }}
              />
              LARGE
              <br />
              {t('artikelnr_tkKlocka')}: TKK 282-00
            </li>
          </ul>
        </ProductDescription>

        <ProductInfo titel={t('tillbehör_tkKlocka')} className="flex">
          <Accessories>
            <AccessImage src={TKKLaddare} alt="" />
            <p>{t('laddare_tkKlocka')} tydlig | kalender Klocka</p>
          </Accessories>
        </ProductInfo>

        <ProductInfo titel={t('dokument_tkKlocka')}>
          <ProductDocument>
            <ul>
              <p>{t('laddaner_tkKlocka')}</p>

              <DownloadList>
                <DownloadButton>
                  <a
                    href={TKK_Produktblad}
                    download="TydligKalenderKlocka_Produktblad.pdf"
                  >
                    <span>tydlig | kalender Klocka Produktblad</span>
                    <MdOutlineFileDownload />
                  </a>
                </DownloadButton>

                <DownloadButton>
                  <a
                    href={FI_TKK_Produktblad}
                    download="FI_Kalender_Klocka_Produktblad.pdf"
                  >
                    <span>tydlig | kalender Klocka Produktblad På Finska</span>
                    <MdOutlineFileDownload />
                  </a>
                </DownloadButton>
              </DownloadList>
            </ul>
          </ProductDocument>

          
        </ProductInfo>
      </ProductSection>
    </main>
  );
};

export default TydligKalenderKlocka;
