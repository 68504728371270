import React from "react";
import ProductInfo from "./ProductInfo";
import { MdOutlineFileDownload } from "react-icons/md";
import { useTranslation } from 'react-i18next';


/* ---------------------------------------------------------------------------- */

import {
  DownloadButton,
  DownloadList,
  ProductDescription,
  ProductDocument,
  ProductImage,
  ProductSection,
} from "./StyledComponents";

/* ----------------------------------------------------------------------------
 *          IMAGES
 * --------------------------------------------------------------------------- */

import MedTimeImage from "../../../../data/images/products/MED Time/med_time.png";

/* ----------------------------------------------------------------------------
 *          DOWNLOAD FILES
 * --------------------------------------------------------------------------- */

import MEDtimeSnabbguide from "../../../../data/downloads/medtime_snabbguide.pdf";

/* ============================================================================ */
/*          TYDLIG KALENDER PRO - function
/* ============================================================================ */

const MEDtime = () => {  
  const { t } = useTranslation("products/medtime"); 

  return (
    <main className="product container">
      <ProductSection>
        <h1>{t('h1_medtime')}</h1>
        <ProductImage src={MedTimeImage} alt="" />

        <ProductDescription>
          <p>{t('p1_medtime')}</p>
          <h3>med | time</h3>
          <ul>
            <li>{t('artikelnr_medtime')}: M201-018</li>
          </ul>
        </ProductDescription>

        <ProductInfo titel={t('dokument_medtime')}>
          <ProductDocument>
            <ul>
              <p>{t('laddaner_medtime')}</p>

              <DownloadList>
                <DownloadButton>
                  <a href={MEDtimeSnabbguide} download="MEDtime_Snabbguide.pdf">
                    <span>med | time Snabbguide</span>
                    <MdOutlineFileDownload />
                  </a>
                </DownloadButton>
              </DownloadList>
            </ul>
          </ProductDocument>
        </ProductInfo>
      </ProductSection>
    </main>
  );
};

export default MEDtime;
