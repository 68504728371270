import { Link } from "react-router-dom";
import styled from "styled-components";

/* ============================================================================ */
/*          STYLED - Styled Components
/* ============================================================================ */

export const HomeSection = styled.section`
    margin-top: 250px;
    overflow-x: hidden;
`;

export const HomeArticles = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 3fr);
    gap: 5rem;

    @media screen and (max-width: 1200px) {
        gap: 2.5rem;
    }

    @media screen and (max-width: 1023px) {
        grid-template-columns: repeat(2, 3fr);
        column-gap: 3rem;
    }

    @media screen and (max-width: 690px) {
        grid-template-columns: repeat(1, 3fr);
        gap: 0;
    }
`;

export const HomeArticle = styled.article`
    display: grid;
    grid-template-columns: repeat(1, 2fr);
    width: 300px;
    margin-bottom: 2.5rem;

    @media screen and (max-width: 1023px) {
        margin-bottom: 0rem;
    }

    @media screen and (max-width: 690px) {
        margin-bottom: 0.5rem;
    }
`;

export const HomeImage = styled.img`
    width: 300px;
    height: 300px;
    object-fit: cover;
    border-radius: 10px;
`;

export const HomeArticleText = styled.div`
    margin-top: 2rem;
    height: screen;
    width: 300px;

    h3 {
        font-size: var(--h3-font-size);
        font-weight: var(--font-medium);
        padding-bottom: 1rem;
    }

    p {
        padding-bottom: 1rem;
    }

    @media screen and (max-width: 690px) {
        height: auto;
        width: 300px;
        margin-bottom: 2.5rem;
    }
`;

export const HomeLink = styled(Link)`
    display: flex;
    align-items: center;
    gap: 1rem;
    line-height: 1.5rem;

    &:hover {
        font-weight: var(--font-medium);
    }
`;
