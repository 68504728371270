import { useState } from "react";
import { GrAdd, GrSubtract } from "react-icons/gr";
import { ProductButton, ProductRow, ProductTitle } from "./StyledComponents";

/* ----------------------------------------------------------------------------
 *      TOGGLE - READ MORE/READ LESS
 * --------------------------------------------------------------------------- */

function ProductInfo({ titel, children }) {
  const [showMore, setShowMore] = useState(false);
  return (
    <ProductRow onClick={() => setShowMore((prev) => !prev)}>
      <ProductTitle>
        <h2>{titel}</h2>
        <ProductButton>
          {showMore ? <GrSubtract /> : <GrAdd />}
        </ProductButton>
      </ProductTitle>
      <div>{showMore && <span>{children}</span>}</div>
    </ProductRow>
  );
}

export default ProductInfo;
