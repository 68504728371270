import styled from "styled-components";
import { SlGlobe } from "react-icons/sl";
import { IoIosArrowDown } from "react-icons/io";



export const LanguageSelectorWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  padding: 10px;
  align-items: center;
  flex-direction: column;

`;

export const LanguageIcon = styled(SlGlobe)`
  color: white !important;
`;

export const ArrowDownIcon = styled(IoIosArrowDown)`
  color: white !important;
`;

export const LanguageSelectorButton = styled.button`
  background-color: var(--icon-color);
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: x-large;

`;

export const Dropdown = styled.div`
  position: absolute;
  top: 100%;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: ${({ open }) => (open ? "flex" : "none")};
  z-index: 1;
  flex-direction: column;
`;

export const DropdownItem = styled.div`
  padding: 8px;
  cursor: pointer;

  &:hover {
    background-color: #f0f0f0;
  }
`;