import React from "react";
import { useState } from 'react';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { PulseLoader } from "react-spinners";
import { useTranslation } from 'react-i18next';


/* ---------------------------------------------------------------------------- */

import {
  LoginSection,
  LoginButton,
  Form,
  FormDiv,
  Label,
  Input
} from "./StyledComponents";

/* ============================================================================ */
/*       LOGIN - Function
/* ============================================================================ */


const Login = () => {
  const { t } = useTranslation("login"); 

  //Get user information

  const navigate = useNavigate();

  //Animation function

  const [loginError, setLoginError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);


  //Get form info to send with the axios req

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  function handleUsernameChange(e) {
    setUsername(e.target.value);
  }

  function handlePasswordChange(e) {
    setPassword(e.target.value);
  }


  //Axios req to the backend to start authentication through passport

  const loggIn = () => {
    setLoginError(false);
    setLoginError(true); // Placeholder
    setIsLoading(true);

    axios
      .post("login/password", {
        username: username,
        password: password
      }) 
      .then(function(res){
          if (res.data === "/login"){
            document.getElementById("username").value = "";
            document.getElementById("password").value = "";
            setLoginError(true);
          }else {
          navigate(res.data)            
          }

      })
      .catch((error) => console.error(error))
      .finally(() => {
        setIsLoading(false);
      });
  };



  return (

  <div className="login container">
    <LoginSection>

      <FormDiv>
    
      <Form className={loginError ? 'shake' : ''}>
        <div class="">
          <Label>
            <span>Användarnamn</span>
          </Label>
          <Input 
          value={username} 
          onChange={handleUsernameChange}
          id="username" 
          type="text" 
          placeholder="Användarnamn" 
          className={loginError ? "error-border" : ""}
          required/>
        </div>
        <div class="">
          <Label>
            <span>Lösenord</span>
          </Label>
          <Input 
          value={password} 
          onChange={handlePasswordChange}
          id="password" 
          type="password" 
          placeholder="*********"
          className={loginError ? "error-border" : ""}
          required />
        </div>
        {loginError && <div className="error-message">Felaktigt Användarnamn eller Lösenord</div>}
        <div class="">
        <LoginButton type="button" onClick={loggIn}>
            {isLoading ? ( 
              <PulseLoader
                className="override"
                color={"#ffffff"}
                margin={10}
                size={10}
                loading={isLoading}
              />
            ) : (
              <span>Logga In</span>
            )}
          </LoginButton>
        </div>
      </Form>         

      <p class="">
        &copy;2023 br | ström
      </p>
    
    </FormDiv>

    </LoginSection>
  </div>
 
  );
};


export default Login;
