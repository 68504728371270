import React from "react";
import { useTranslation } from 'react-i18next';

/* ----------------------------------------------------------------------------
 *          DATA
 * --------------------------------------------------------------------------- */

import { GetTranslatedPersonalData } from "./PersonalData.jsx";

/* ---------------------------------------------------------------------------- */

import {
  AboutDescription,
  AboutHeader,
  AboutSection,
  AboutTeam,
  PersonalCard,
  PersonalHobby,
  PersonalImage,
  PersonalName,
  PersonalNumber,
  PersonalText,
  PersonalWork,
  TeamCards,
} from "./StyledComponents.jsx";

/* ============================================================================ */
/*       ABOUT - Function
/* ============================================================================ */

const About = () => {
  const { t, i18n } = useTranslation("about"); 

  const translatedPersonalData = GetTranslatedPersonalData(i18n.language);

  return (
    <main className="about container">
      <AboutHeader>
        <h1>{t('h1_about')}</h1>

        <AboutDescription>
          <p>{t('p1_about')}</p>
          <p>{t('p2_about')}</p>
          <p>{t('p3_about')}</p>
        </AboutDescription>
      </AboutHeader>

      <AboutSection>
      <h2>{t('h2_about')}</h2>
      <AboutTeam>
        <TeamCards>
          {translatedPersonalData.map((item, index) => {
            return (
              <PersonalCard key={index}>
                <PersonalImage src={item.image} alt="" />
                <PersonalText>
                  <PersonalName>{item.name}</PersonalName>
                  <PersonalNumber>{item.number}</PersonalNumber>
                  <PersonalWork>
                    <b>{item.title}</b>
                    {item.info}
                  </PersonalWork>
                  <PersonalHobby>{item.hobby}</PersonalHobby>
                </PersonalText>
              </PersonalCard>
            );
          })}
        </TeamCards>
      </AboutTeam>
      </AboutSection>
    </main>
  );
};

export default About;