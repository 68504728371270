import React from "react";
import ProductInfo from "./ProductInfo";
import { MdOutlineFileDownload } from "react-icons/md";
import { useTranslation } from 'react-i18next';
 
/* ---------------------------------------------------------------------------- */

import {
  DownloadButton,
  DownloadList,
  ProductDescription,
  ProductDocument,
  ProductImage,
  ProductSection,
} from "./StyledComponents";

/* ----------------------------------------------------------------------------
 *          IMAGES
 * --------------------------------------------------------------------------- */

import WellbeeAppLogo from "../../../../data/images/products/Wellbee App/wellbee-app.png";

/* ----------------------------------------------------------------------------
 *          DOWNLOAD FILES
 * --------------------------------------------------------------------------- */

import WellbeeProduktblad from "../../../../data/downloads/wellbee-produktblad.pdf";
import FI_WellbeeProduktblad from "../../../../data/downloads/FI_Wellbee_Produktblad.pdf"
import NO_WellbeeManual from "../../../../data/downloads/NO_Brukermanual_wellbee.pdf"

/* ============================================================================ */
/*          TYDLIG KALENDER PRO - function
/* ============================================================================ */

const WellbeeApp = () => {  
  const { t } = useTranslation("products/wellbeeApp");

  return (
    <main className="product container">
      <ProductSection>
        <h1>{t('h1_wellbeeApp')}</h1>
        <ProductImage src={WellbeeAppLogo} alt="" style={{borderRadius: "10px", boxShadow: "5px 5px 20px rgba(0, 0, 0, 0.1)"}}/>

        <ProductDescription>
          <p>{t('p1_wellbeeApp')}</p>
          <p>{t('p2_wellbeeApp')}</p>
          <h3>wellbee APP</h3>
          <ul>
            <li>{t('artikelnr_wellbeeApp')}: N8010-00</li>
          </ul>
        </ProductDescription>

        <ProductInfo titel={t('dokument_wellbeeApp')}>
          <ProductDocument>
            <ul>
              <p>{t('laddaner_wellbeeApp')}</p>

              <DownloadList>
                <DownloadButton>
                  <a href={WellbeeProduktblad} download="Wellbee_Produktblad.pdf">
                    <span>Wellbee Produktblad</span>
                    <MdOutlineFileDownload />
                  </a>
                </DownloadButton>

                <DownloadButton>
                  <a href={FI_WellbeeProduktblad} download="FI_Wellbee_Produktblad.pdf">
                    <span>Wellbee Produktblad På Finska</span>
                    <MdOutlineFileDownload />
                  </a>
                </DownloadButton>
                
                <DownloadButton>
                  <a href={NO_WellbeeManual} download="NO_Brukermanual_wellbee.pdf">
                    <span>Wellbee Manual På Norska</span>
                    <MdOutlineFileDownload />
                  </a>
                </DownloadButton>
              </DownloadList>
            </ul>
          </ProductDocument>
        </ProductInfo>
      </ProductSection>
    </main>
  );
};

export default WellbeeApp;
