import React from "react";
import { useTranslation } from 'react-i18next';

import {
  ServicesHeader,
  ServicesHeaderImage,
  ServicesHeaderText,
  ServicesSection,
  ServicesDescription,
  ServicesArticles,
  ServicesArticle,
} from "./StyledComponents.jsx";

/* ----------------------------------------------------------------------------
 *          IMAGES
 * --------------------------------------------------------------------------- */

import Header from "../../../data/images/services.png";

/* ============================================================================ */
/*       SERVICES - Function
/* ============================================================================ */

const Services = () => {
  const { t } = useTranslation("services");

  return (
    <main className="services container">
      <ServicesHeader>
        <ServicesHeaderImage src={Header} alt="" />
        <ServicesHeaderText>
          <h1>{t('h1_services')}</h1>
          <h3>{t('h3_1_services')}</h3>
        </ServicesHeaderText>
      </ServicesHeader>

      <ServicesSection>
        <ServicesDescription>{t('desc_services')}</ServicesDescription>

        <ServicesArticles>
          <ServicesArticle>
            <h2>{t('h2_1_services')}</h2>
            <p>{t('p1_services')}</p>
          </ServicesArticle>

          <ServicesArticle>
            <h2>{t('h2_2_services')}</h2>
            <span>
              <h3>{t('h3_2_services')}</h3>
              <p>{t('p2_services')}</p>
              <h3>{t('h3_3_services')}</h3>
              <p>{t('p3_services')}</p>
              <h3>{t('h3_4_services')}</h3>
              <p>{t('p4_services')}</p>
            </span>
          </ServicesArticle>

          <ServicesArticle>
            <h2>{t('h2_3_services')}</h2>
            <p>{t('p5_services')}</p>
          </ServicesArticle>

          <ServicesArticle>
            <h2>{t('h2_4_services')}</h2>
            <p>{t('p6_services')}</p>
          </ServicesArticle>
        </ServicesArticles>
      </ServicesSection>
    </main>
  );
};

export default Services;
