import React from "react";
import ProductInfo from "./ProductInfo";
import { MdOutlineFileDownload } from "react-icons/md";
import { useTranslation } from 'react-i18next';

/* ---------------------------------------------------------------------------- */

import {
  DownloadButton,
  DownloadList,
  ProductDescription,
  ProductDocument,
  ProductImage,
  ProductSection,
} from "./StyledComponents";

/* ----------------------------------------------------------------------------
 *          IMAGES
 * --------------------------------------------------------------------------- */

import ValterImage from "../../../../data/images/products/Valter/valter.png";


/* ----------------------------------------------------------------------------
 *          DOWNLOAD FILES
 * --------------------------------------------------------------------------- */

import ValterProduktblad from "../../../../data/downloads/valter-produktblad.pdf";

/* ============================================================================ */
/*          TYDLIG KALENDER PRO - function
/* ============================================================================ */

const Valter = () => {  
  const { t } = useTranslation("products/valter"); 

  return (
    <main className="product container">
      <ProductSection>
        <h1>{t('h1_valter')}</h1>
        <ProductImage src={ValterImage} alt="" />

        <ProductDescription>
          <p>{t('p1_valter')}</p>
          <p>{t('p2_valter')}</p>
          <p>{t('p3_valter')}</p>
          <p>{t('p4_valter')}</p>
          <p>{t('p5_valter')}</p>
          <p>{t('p6_valter')}</p>
          <p>{t('p7_valter')}</p>

          <h3>{t('h3_valter')}</h3>
          <ul>
            <li>{t('artikelnr_valter')}: VLT-951</li>
          </ul>
          <br />
          <span>
            <ul>
              <li>{t('li1_valter')}</li>
              <li>{t('li2_valter')}</li>
              <li>{t('li3_valter')}</li>
              <li>{t('li4_valter')}</li>
              <li>{t('li5_valter')}</li>
              <li>{t('li6_valter')}</li>
              <li>{t('li7_valter')}</li>
              <li>{t('li8_valter')}</li>
              <li>{t('li9_valter')}</li>
              <li>{t('li10_valter')}</li>
              <li>{t('li11_valter')}</li>
              <li>{t('li12_valter')}</li>
              <li>{t('li13_valter')}</li>
              <li>{t('li14_valter')}</li>
              <li>{t('li15_valter')}</li>
              <li>{t('li16_valter')}</li>
            </ul>
          </span>
        </ProductDescription>

        <ProductInfo titel={t('dokument_valter')}>
          <ProductDocument>
            <ul>
              <p>{t('laddaner_valter')}</p>

              <DownloadList>
                <DownloadButton>
                  <a href={ValterProduktblad} download="Valter_Produktblad.pdf">
                    <span>Valter Produktblad</span>
                    <MdOutlineFileDownload />
                  </a>
                </DownloadButton>
              </DownloadList>
            </ul>
          </ProductDocument>
        </ProductInfo>
      </ProductSection>
    </main>
  );
};

export default Valter;
