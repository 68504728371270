import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

/* ============================================================================ */
/*          STYLED - Styled Components
/* ============================================================================ */

const SidebarLink = styled(Link)`
  display: flex;
  color: var(--text-color);
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  list-style: none;
  height: 50px;
  text-decoration: none;
  font-size: var(--h3-font-size);
  transition: 350ms;
  

  &:hover {
    cursor: pointer;
    background-color: rgba(48, 48, 48, 0.1);
    transition: 350ms;
  }
`;

const SidebarLabel = styled.span`
  margin-left: 16px;
  `;

const DropdownLink = styled(Link)`
  background-color: none;
  padding: 0.5rem 0 0.5rem 3rem;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  color: var(--text-color);
  font-size: var(--normal-font-size);
  font-weight: var(--font-medium);
  transition: 350ms;

  &:hover {
    cursor: pointer;
    background-color: rgba(48, 48, 48, 0.1);
    transition: 350ms;
  }
`;

/* ============================================================================ */
/*          SUBMENU - Function
/* ============================================================================ */

const SubMenu = ({ item }) => {
  const [subnav, setSubnav] = useState(false);
  const showSubnav = () => setSubnav(!subnav);

  return (
    <>
      <SidebarLink to={item.path} onClick={item.subNav && showSubnav}>
        <div>
          {item.icon}
          <SidebarLabel>{item.title}</SidebarLabel>
        </div>
        <div>
          {item.subNav && subnav
            ? item.iconOpened
            : item.subNav
            ? item.iconClosed
            : null}
        </div>
      </SidebarLink>
<div>
        {subnav &&
          item.subNav.map((item, index) => {
            return (
              
              <DropdownLink to={item.path} key={index}>
                <SidebarLabel>{item.title}</SidebarLabel>
              </DropdownLink>
            );
          })}
   </div>
    </>
  );
};

export default SubMenu;
