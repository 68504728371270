import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useUser } from '../stores/userContext.js'; 

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { user, checkLoggedIn } = useUser();
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true); 
  // ska fixa med laddnings animation när du håller på att loggas in 

  useEffect(() => {
    console.log("Checking login status");
    if (location.pathname !== '/login') {
        if (!user){
        checkLoggedIn()
        .then(() => setLoading(false))
        .catch((error) => {
          setLoading(false);
          console.error(error);
        });           
        }

    } else {
      setLoading(false);
    }
  }, [location.pathname, checkLoggedIn]);


  if (location.pathname === '/tekniker' && !user) {
    navigate('/login');
    return null;
  } else {
    return <Component />;
  }
};

export default PrivateRoute;