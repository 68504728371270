import React from "react";
import { useTranslation } from 'react-i18next';

/* ----------------------------------------------------------------------------
 *      IMAGES
 * --------------------------------------------------------------------------- */

import Services from "../../data/images/radgivning.png";
import Team from "../../data/images/team.png";
import Help from "../../data/images/kontakta.png";

/* ---------------------------------------------------------------------------- */

import { HiArrowNarrowRight } from "react-icons/hi";

/* ---------------------------------------------------------------------------- */

import {
  HomeArticle,
  HomeArticles,
  HomeArticleText,
  HomeImage,
  HomeLink,
  HomeSection,
} from "./StyledComponents";

/* ============================================================================ */
/*       HOME - Function
/* ============================================================================ */

const Home = () => {
  
  const { t } = useTranslation("home"); 

  return (
    <main className="home">
      <HomeSection className="flex">
        <HomeArticles>
          {/* ---------------------------------------------------------------------------- */}
          <HomeArticle className="flex">
            <HomeImage src={Services} alt="" />
            <HomeArticleText>
              <h3>{t('h3_tjanster')}</h3>
              <p>{t('p_tjanster')}</p>
              <HomeLink to="/tjanster">
                <HiArrowNarrowRight />
                <span>{t('link_1_tjanster')}</span>
              </HomeLink>
              <HomeLink to="/produkter">
                <HiArrowNarrowRight />
                <span>{t('link_2_tjanster')}</span>
              </HomeLink>
            </HomeArticleText>
          </HomeArticle>
          {/* ---------------------------------------------------------------------------- */}
          <HomeArticle className="flex">
            <HomeImage src={Team} alt="" />
            <HomeArticleText>
              <h3>{t('h3_team')}</h3>
              <p>{t('p_team')}</p>
              <HomeLink to="/om">
                <HiArrowNarrowRight />
                <span>{t('link_1_team')}</span>
              </HomeLink>
            </HomeArticleText>
          </HomeArticle>
          {/* ---------------------------------------------------------------------------- */}
          <HomeArticle className="flex">
            <HomeImage src={Help} alt="" />
            <HomeArticleText>
              <h3>{t('h3_kontakt')}</h3>
              <p>{t('p_kontakt')}</p>
              <HomeLink to="/kontakt">
                <HiArrowNarrowRight />
                <span>{t('link_1_kontakt')}</span>
              </HomeLink>
            </HomeArticleText>
          </HomeArticle>
          {/* ---------------------------------------------------------------------------- */}
        </HomeArticles>
      </HomeSection>
    </main>
  );
};

export default Home;