import styled from "styled-components";

/* ============================================================================ */
/*          STYLED - Styled Components
/* ============================================================================ */

export const ProductImage = styled.img`
  height: 250px;
  margin-top: 2rem;

  
  @media screen and (max-width: 480px) {
    height: 200px;
  }
`;

export const ProductSection = styled.section`
  margin-top: 130px;
  text-align: center;

  p {
    padding-bottom: 1rem;
    text-align: left;
    font-size: var(--normal-font-size);
    line-height: 1.5;
  }
`;

export const ProductDescription = styled.div`
  text-align: left;
  margin-bottom: 3rem;
  padding-top: 1.5rem;

  h3 {
    padding: 2.5rem 0 0.5rem;
  }

  li {
    padding-top: 0.5rem;
  }
`;

export const ProductRow = styled.div`
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
  margin-bottom: 1rem;
  transition: all 0.5 ease-in-out;
  transition: height 0.5s ease-in-out;

  p {
    text-align: left;
  }
`;

export const ProductTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  font-size: 0.85rem;
`;

export const ProductDocument = styled.div`
  padding: 0 2rem 1rem 2rem;
  
  p {
    padding-left: 1rem;
  }

  ul {
    padding-top: 1rem;
    font-weight: var(--font-medium);
  }

  li {
    display: flex;
    align-items: center;
    gap: 1rem;
    height: 25px;
    padding: 0 1.5rem;
    margin-bottom: 1rem;
  }

  
  @media screen and (max-width: 480px) {
    padding: 0.5rem 0.5rem;
  }
`;

export const DownloadButton = styled.button`
  border: none;
  padding: 0.5rem 0.5rem;
  cursor: pointer;
  background-color: transparent;
  border-radius: 5px;
  transition: 0.3s;

  &:hover {
    background-color: rgba(48, 48, 48, 0.1);
  }
`;

export const DownloadList = styled.li`
  display: flex;
  justify-content: space-between;

  span {
    padding-right: 1rem;
  }

  a {
    display: flex;
    align-items: center;
  }

  @media screen and (max-width: 480px) {
    width: 400px;
    a {
      align-items: left;
      text-align: left;
    }
    span {
    padding-right: 1rem;
  }
  }

  @media screen and (max-width: 350px) {
    width: 300px;
    a {
      font-size: var(--smaller-font-size);
      align-items: left;
      text-align: left;
    }
    span {
    padding-right: 1rem;
  }
  }
`;

export const ProductButton = styled.div`
  font-size: 1.5rem;
  width: 50px;
  height: 50px;
  background-color: transparent;
  padding: 1rem 2rem;
  cursor: pointer;
`;

export const Accessories = styled.div`
  padding: 0 2rem 1rem 2rem;
  text-align: left;

  p {
    padding-top: 1rem;
  }

  li {
    padding-bottom: 1rem;
  }
`;

export const AccessImage = styled.img`
  height: 150px;

  @media screen and (max-width: 1024px) {
    height: 100px;
}
`;

export const ProductFlex = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;

  @media screen and (max-width: 840px) {
    display: grid;
    grid-template-columns: repeat(2, 2fr);
    padding: 0 1rem;
  }

  @media screen and (max-width: 840px) {
    padding-top: 2rem;
    grid-template-columns: repeat(1, 3fr);
    justify-items: center;
    align-items: center;
  }
`;